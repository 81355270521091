//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    XModel: () => import("@/components/XModal"),
  },
  props: {
    selMsg: {
      type: Object,
      default: null,
    },
    activeItem: {
      type: String,
      default: null,
    },
  },
  methods: {
    imgChange,
  },
};
